import React, { useEffect,useState } from "react";
import "./AboutUs.css";
import HeroSection from "../HeroSection/HeroSection";
import sec2Img from '../../assets/sec2rightImg.png';
import sec2ImgMob from '../../assets/s2img-mob.png'
import s3img1 from '../../assets/s3img1.png';
import VideoPlayer from '../Video/Videoplayer';
const AboutUs = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension(){
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  useEffect(() => {
      const updateDimension = () => {
          setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenSize])
  return (
    <div>
      <HeroSection />
      <div className="main-container-hero-section">
        <div className="section1">
        <VideoPlayer />
        </div>

        <div className="section2-about-us">
          <div className="left-section" style={{}}>
            {screenSize.width>850 && <div className="heading">
              <p>FIND YOUR LIFE PARTNER TODAY</p>
            </div>
            }
            {screenSize.width<=850 &&
            <div className="joompaForever"><p >
              Joompa... where forever begins
            </p>
            </div>
          }
            <div className="large-content">
              <div>
                <p>The latest</p>
              </div>
              <div className="matching">
                <p>matchmaking app</p>
              </div>
              <div>
                <p>created for Muslims,</p>
              </div>
              <div>
                <p>by Muslims</p>
              </div>
            </div>
            <div className="small-content">
              <div>
                <p>Joompa is your partner in making your digital dream connections a reality.</p>
              </div>
            </div>
          </div>

          <img src={sec2Img} className="section-2-img" style={{ padding: '50px', width: '575px', height: 'auto' }} />
          <img src={sec2ImgMob} className="section-2-mob-img" />

        </div>

        <div className="section3">
          <div className="imgsec3">
            <img className="smileman" src={s3img1} alt="smiling man" />
          </div>
          <div className="textsec3">
            {/* <p style={{ color: "#1B75BC", paddingBottom: "20px", textTransform: 'uppercase' }}>
              Connecting Muslims throughout the region
            </p> */}
            
            {screenSize.width>850 && 
             <div className="joompaForever" style={{textAlign:"left",marginBottom:"16px"}}><p >
             Joompa... where forever begins
           </p>
           </div>
            }
           
            <div>
              <h1>More..</h1>
              <h1 style={{ color: "#1B75BC", paddingTop: "0.5rem" }}>Choices</h1>
              <h1 style={{ color: "#EF1D4F", paddingTop: "0.5rem" }}>Control</h1>
              <h1 style={{ color: "#4DAE47", paddingTop: "0.5rem" }}>Compatible</h1>
            </div>

           
            <div className="boxes1" style={{ paddingTop: "20px" }}>
              <div className="smbox1">
                <h2 className="boxtext1" style={{ color: "#189E48" }}>
                  11
                </h2>
                <p className="boxtext21">Countries</p>
              </div>
              <div className="smbox2">
                <h2 className="boxtext2" style={{ color: "#189E48" }}>1.8k</h2>
                <p className="boxtext22">Matches</p>
              </div>
            </div>
            <div className="boxes2" style={{ paddingTop: "20px" }}>
              <div className="smbox3">
                <h2 className="boxtext3" style={{ color: "#189E48" }}>1.5k</h2>
                <p className="boxtext23">5 Star Reviews</p>
              </div>
              <div className="smbox4">
                <h2 className="boxtext4" style={{ color: "#189E48" }}>2k+</h2>
                <p className="boxtext24">Users</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section3-mobile">

          <div className="textsec3">
            {/* <p style={{ color: "#1B75BC", paddingBottom: "20px", textTransform: 'uppercase', textAlign:'center'}}>
              Connecting Muslims throughout the region
            </p> */}
             {screenSize.width<=850 &&
            
            <div className="headingMobile">
            <p>FIND YOUR LIFE PARTNER TODAY</p>
          </div>
          }
            

            {/* <p style={{ color: "rgba(25, 24, 37, 0.5)", paddingTop: "25px", textAlign:'center' }}>
              Joompa... where forever begins
            </p> */}
            <div style={{textAlign:'left',margin:"auto",width:"80%"}}>
              <h1>More..</h1>
              <h1 style={{ color: "#1B75BC", paddingTop: "0.5rem" }}>Choices</h1>
              <h1 style={{ color: "#EF1D4F", paddingTop: "0.5rem" }}>Control</h1>
              <h1 style={{ color: "#4DAE47", paddingTop: "0.5rem" }}>Compatible</h1>
            </div>
            <div className="boxes1" style={{ paddingTop: '20px', flexDirection:'column'}}>
              <div className="smbox1" style={{width:'100%'}}>
                <h2 className="boxtext1" style={{ color: "#189E48",fontSize:"25px" }}>
                  11
                </h2>
                <p className="boxtext21"  style={{fontSize:"17px" }}>Countries</p>
              </div>
              <div className="smbox2" style={{ marginTop: '20px'}}>
                <h2 className="boxtext2" style={{ color: "#189E48",fontSize:"25px"  }}>1.8k</h2>
                <p className="boxtext22"  style={{fontSize:"17px" }}>Matches</p>
              </div>
            </div>
            <div className="boxes2" style={{ paddingTop: '20px', flexDirection:'column'}}>
              <div className="smbox3" style={{width:'100%'}}>
                <h2 className="boxtext3" style={{ color: "#189E48",fontSize:"25px"   }}>1.5k</h2>
                <p className="boxtext23" style={{fontSize:"17px" }}>5 Star Reviews</p>
              </div>
              <div className="smbox4" style={{  marginTop: '20px'}}>
                <h2 className="boxtext4" style={{ color: "#189E48",fontSize:"25px"   }}>2k+</h2>
                <p className="boxtext24" style={{fontSize:"17px" }}>Users</p>
              </div>
            </div>
          </div>

          <div className="imgsec3">
            <img className="smileman" src={s3img1} alt="smiling man" style={{width:'250px', height:'auto'}}/>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AboutUs;
