import React, { useEffect,useState } from 'react'
import joompaApi from '../../apis/joompaApi';
import './TandC.css'
import moment from 'moment/moment';

function TermsCondition() {
    const [data,setData]=useState();
    const getTandC = async()=>{
        try{
            let res = await joompaApi.get(`/termsAndCondition`);
            let {data} = res?.data;
            setData(data);

        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getTandC();
    },[])

  return (
    <div className='container-main-TC'>
    <div className='container-TC'>
        <div className='heading-TC'>
        Terms & Condition 
        </div>   
        <div className='sub-heading-TC'>
            Last Updated on : {data && moment(data.updatedAt).format("DD-MM-YYYY")}
        </div>
        </div>   
        {/* <div style={{marginTop:"20px"}}>
            {data && data.content}    
        </div>  */}
        {data && <div style={{marginTop:"20px"}}dangerouslySetInnerHTML={{ __html: data.content }}/>}
    </div>
  )
}

export default TermsCondition