import React, { useRef, useState } from "react";
import "./ContactUs.css";
import ContactUsImg from "../../assets/contactUs-img3png.png";
import ContactUsMobImg from "../../assets/contactUs-mob-img.png";
import confirmedIcon from "../../assets/confirmed-icon.svg";
import { Modal, message } from "antd";
import joompaApi from "../../apis/joompaApi";

const ContactUs = () => {
  const [showMessageContainer, setShowMessageContainer] = useState(false);
  const fullName = useRef();
  const email = useRef();
  const looking = useRef();
  const messages = useRef();
  const handleSendButtonClick = async (e) => {
    if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email.current.value)){
      message.error("Please Enter Valid Email Address");
      return;
    }
   
    if(!fullName.current.value || !email.current.value || !messages.current.value){
        message.error("Please fill all mandatory fields");
        return;
    }
    try {
      let res = await joompaApi.post(`/enquiry`,{
            "name": fullName.current.value,
            "email": email.current.value,
            "message": looking.current.value,
            "userMessage": messages.current.value
      });
      let { data } = res;
      if(data.success){
        Modal.success({
            centered: true,
            content: "Your message has been sent. Thank you",
          });
          fullName.current.value = "";
          email.current.value = "";
          looking.current.value = "General Enquires for App";
          messages.current.value = "";
      }
    } catch (err) {
      console.log(err);
    }

    // When the "SEND MESSAGE" button is clicked, set showMessageContainer to true
   
    // setShowMessageContainer(true);
  };

  const handleCloseButtonClick = () => {
    setShowMessageContainer(false);
  };

  return (
    <>
      <div className="main-contactUs-container">
        <div className="headings">
          <div className="main-heading">
            <p>
              <b>Contact Us</b>
            </p>
          </div>

          <div className="sub-heading">
            <p>Any question or remarks? Just write us a message!</p>
          </div>
        </div>

        <div className="main-content">
          <img src={ContactUsImg} alt="img" className="contact-us-img" />

          <img
            src={ContactUsMobImg}
            alt="mob-img"
            className="mob-contact-us-img"
          />

          <div className="right-section">
            <div className="form-heading">
              <p className="say-hi">Say Hi!</p>
              <p className="below-say-hi">We’d like to talk with you.</p>
            </div>

            <div className="form" >
              <div className="field">
                <div className="label">My name is<span style={{color:"red"}} > *</span></div>
                <input type="text" ref={fullName} placeholder="Full Name" />
              </div>

              <div className="field">
                <div className="label">Email Id<span style={{color:"red"}} > *</span></div>
                <input type="email" ref={email} placeholder="Your Email Id" />
              </div>

              <div className="field">
                <div className="label">I'm looking for</div>
                <select ref={looking}>
                  <option value="General Enquires for App">
                    General Enquires for App
                  </option>
                  <option value="Payments">Payments</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div className="field">
                <div className="label">Your Message<span style={{color:"red"}} > *</span></div>
                <textarea
                  ref={messages}
                  rows={4}
                  placeholder="I want to say that..."
                />
              </div>

              <div className="btn" style={{ width: "87%" }}>
                <button className="send-us-btn" type="submit" onClick={(e)=>{
                  handleSendButtonClick(e)
                  e.target.disabled = true;
                  setTimeout(()=>{
                    e.target.disabled=false;
                  },2000) 
                }}>
                  SEND MESSAGE
                </button>
              </div>

              {showMessageContainer && (
                <div className="success-msg-container">
                  <div>
                    <img
                      src={confirmedIcon}
                      alt="confirmed-icon"
                      className="confirmed-icon"
                    />
                  </div>
                  <div className="success-text">
                    <div style={{ fontSize: "1rem" }}>
                      Your Message Sent Successfully!
                    </div>
                    <div style={{ fontSize: "0.6rem" }}>
                      Our team will get back to you within 24 hours.
                    </div>
                  </div>
                  <div>
                    <button
                      className="send-us-btn"
                      onClick={handleCloseButtonClick}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='btn' style={{width: "80%"}}>
                        <button className='send-us-btn'>SEND MESSAGE</button>
                    </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
