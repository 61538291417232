import React, {useEffect} from "react";
import "./Premium.css";
import blackbg from "../../assets/blackbg.png";
import oval from "../../assets/Oval.png";
import preimg1 from "../../assets/preimg1.png";
import preimg2 from "../../assets/preimg2.png";
import sec1img1 from "../../assets/sec1img1.png";
import sec1img2 from "../../assets/sec1img2.png";
import sec2img1 from "../../assets/sec2img1.png";
import sec2img2 from "../../assets/sec2img2.png";
import iphonex from "../../assets/iPhoneX.png";
import phones from "../../assets/phones.png";
import phones2 from "../../assets/phones2.png";
import heroImg from "../../assets/premium-hero-img.png";

const Premium = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="bg" style={{ backgroundImage: `url(${blackbg})` }}>
        <div className="upperbg" style={{ backgroundImage: `url(${oval})` }}>
          <div className="headtext">
            <h1 className="premium-hero-heading-desk" style={{ fontSize:"4rem", paddingBottom:"1rem"}}>Get the best with Premium Bundle</h1>
            <h1 className="premium-hero-heading">Get the best with Premium Bundle</h1>
            <p style={{opacity:"0.5" , fontSize:"1rem"}}>Premium subscription allows unlimited usage of pay per go features such as Audio Calls, Video Calls, Chat Messages, etc.</p>
          </div>
          
          <div
            className="mobImgBg"
            style={{ backgroundImage: `url(${preimg2})` }}
          >
            
            <img
              style={{ paddingLeft: "15rem", paddingBottom:"2rem" }}
              className="image1"
              src={preimg1}
              alt=""
            />
          </div>

          <div className="mobImg-mobile">
            
            <img
              
              className="image1-mob"
              src={heroImg}
              alt=""
            />
          </div>

        </div>
      </div>
      <div className="section1-premium">
        <div className="sec1text">
          <h1 style={{ fontSize: "2.5rem", paddingBottom: "1rem" }}>
            Concierge by Joompa
          </h1>
          <p
            style={{ opacity: "0.5", paddingRight: "3rem", color: "#272C35", fontWeight:"600", fontSize:"0.9rem"}}
          >
            Tired of trying multiple match making apps? Get a personal match
            maker to find the ideal match for you with Joompa Concierge. The
            feature is available to limited users only.{" "}
          </p>
        </div>
        <div className="sec1img">
          <div
            style={{
              backgroundImage: `url(${sec1img1})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "1.7rem",
              backgroundPositionY: "4rem",
              backgroundSize: "12.5rem",
            }}
          >
            <img
              src={iphonex}
              style={{
                width: "13.8rem",
                paddingRight: "3rem",
                paddingLeft: "1rem",
                height: "28rem",
              }}
            />
          </div>
          <div
            style={{
              backgroundImage: `url(${sec1img2})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "1.9rem",
              backgroundPositionY: "10rem",
              backgroundSize: "12rem",
            }}
          >
            <img
              src={iphonex}
              style={{
                width: "13.8rem",
                paddingRight: "3rem",
                paddingLeft: "1rem",
                height: "28rem",
                paddingTop: "6rem",
              }}
            />
          </div>
        </div>
      </div>

      <div className="section1-mob">
        <div className="sec1text">
          <h1 style={{ fontSize: "1.5rem", paddingBottom: "1rem", textAlign:'center'}}>
            Concierge by Joompa
          </h1>
          <p
            style={{ opacity: "0.5", color: "#272C35", fontWeight:"600", fontSize:"0.9rem", textAlign:'center'}}
          >
            Tired of trying multiple match making apps? Get a personal match
            maker to find the ideal match for you with Joompa Concierge. The
            feature is available to limited users only.{" "}
          </p>
        </div>
        <div className="sec1img">
          <div className="conce-img-container">
            <img
              src={phones}
              style={{
                width: "250px",
                height:"auto",
              }}
            />
          </div>
         
        </div>
      </div>

      <div className="section2">
        <div className="sec2img">
          <div
            className="iphonebg"
            style={{
              backgroundImage: `url(${sec2img1})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "1rem",
              backgroundPositionX: "0.5rem",
              backgroundSize: "12.5rem",
            }}
          >
            <img
              src={iphonex}
              style={{
                width: "13.8rem",
                paddingRight: "3rem",
                height: "28rem",
              }}
            />
          </div>
          <div
            style={{
              backgroundImage: `url(${sec2img2})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "6.8rem",
              backgroundPositionX: "0.5rem",
              backgroundSize: "12.5rem",
            }}
          >
            <img
              
              src={iphonex}
              style={{
                width: "13.8rem",
                paddingRight: "3rem",
                paddingTop: "6rem",
                height: "28rem",
              }}
            />
          </div>
        </div>
        <div className="sec2text"> 
          <h1 style={{ fontSize: "2.8rem", paddingBottom: "1rem" }} className="reconsider-text">
            Want to reconsider after saying no?
          </h1>
          <p
            style={{ opacity: "0.5", paddingRight: "8rem", color: "#272C35", fontWeight:"600" }}
          >
            Joompa Redo allow you to give another chance to a profile you said
            no initially.{" "}
          </p>
        </div>
      </div>

      <div className="section2-mob">

      <div className="sec2text">
          <h1 style={{ fontSize: "1.5rem", paddingBottom: "1rem", textAlign:'center'}}>
            Want to reconsider after saying no?
          </h1>
          <p
            style={{ opacity: "0.5", color: "#272C35", fontWeight:"600", textAlign:'center' }}
          >
            Joompa Redo allow you to give another chance to a profile you said
            no initially.{" "}
          </p>
        </div>

        <div className="sec2img">
          <div className="reconsider-img">
            <img
              src={phones2}
              style={{
                width: "250px",
                height:"auto",
              }}
            />
          </div>
        </div>
        
      </div>

    </div>
  );
};

export default Premium;
