import React, { useState } from 'react'
import './HeroSection.css'
import PlayStoreBtn from '../../assets/playStorebtn.png'
import AppStoreBtn from'../../assets/appStorebtn.png'
import Symbol from '../../assets/symbol.png'
// import videoBg from '../../assets/herosectionvideo.mp4'
import videoBg1 from '../../assets/Joompa Set B.mov'
import mobVideoBg from '../../assets/new-mob-hero-bg.MOV'
import joompaIcon from '../../assets/joompa-icon.svg'
import {LoadingOutlined } from "@ant-design/icons";
// import videoPoster from '../../assets/thumbnail.png'
import videoPoster from "../../assets/Screenshot 2023-09-06 at 6.03.39 PM.png"

const HeroSection = () => {
  // const [loaded,setLoaded]=useState(true);

  return (
    <div className='main-hero-container'>
      <div className='video-overlay'></div>
      {/* {loaded && <img  className="videoPoster background-vid" src={videoPoster}/>}  poster={videoPoster}*/}
     <video src={videoBg1} autoPlay playsInline loop muted poster={videoPoster}
    //   onLoadStart={() => {
    //     console.log('...I am loading...')
    //     setLoaded(true);
    //     // this.setState({ isLoading: true });
    // }}
    // onLoadedData={() => {
    //     console.log('Data is loaded!')
    //     setLoaded(false);
    //     // this.setState({ isLoading: false });
    // }}
      className='background-vid'/>
  
      <video src={mobVideoBg}
    //    onLoadedData={() => {
    //     console.log('Data is loaded!')
    //     setLoaded(false);
    //     // this.setState({ isLoading: false });
    // }}
     autoPlay playsInline loop muted className='mob-background-vid'/>
      <div className='content'>
      <div className='contentContainer'>
        <img src={joompaIcon} alt='symbol' className='contentImg' style={{width:'150px'}}/>
        {/* {loaded && <LoadingOutlined style={{position:"absolute",fontSize:"40px",color:"#0D9A48"}}/>} */}
        <div className='download-btn'>
          
          <a href="https://play.google.com/store/apps/details?id=com.letsjoompa&pcampaignid=web_share">
          <img src={PlayStoreBtn} style={{cursor:'pointer'}} alt='play-store-btn' className='playstore-btn'/>
          </a>
          <a href="https://apps.apple.com/us/app/lets-joompa/id6457205501">
          <img src={AppStoreBtn} style={{cursor:'pointer'}} alt='app-store-btn' className='appstore-btn'/>
          </a>
         
        </div>
      </div>
      </div>
    </div>
  )
}

export default HeroSection