import "./App.css";
import { useCallback, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import { Footer } from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Premium from "./components/Premium/Premium";
import React from "react";
import Privacy from "./components/Privacy/Privacy";
import TermsCondition from "./components/terms&condition/Terms&condition";
import trackPathForAnalytics from "./trackPathForAnalytics";
import PaymentStatus from "./components/PaymentStatus/PaymentStatus";

function ScrollToTop() {
  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
    trackPathForAnalytics({
      path: pathname,
      search: search,
      title: pathname.split("/")[1],
    });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const location = useLocation();
  return (
    <div className="App">
      {location.pathname.includes("payment-status") ? "" : <Navbar />}
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<AboutUs />} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="terms-and-conditions" element={<TermsCondition />} />
        <Route path="payment-status" element={<PaymentStatus />} />
      </Routes>
      {location.pathname.includes("payment-status") ? "" : <Footer />}
    </div>
  );
}

export default App;
