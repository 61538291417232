import React, { useState } from 'react'
import './NavModal.css'
import { NavLink } from 'react-router-dom';
import { CgFacebook } from "@react-icons/all-files/cg/CgFacebook";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";

const NavModal = (props) => {

    const {isOpen, setNavType, setIsOpen} = props;

  return (
    <div className={`main-modal-container ${isOpen ? 'unhide' : 'hide'}`}>
        <div className='mob-nav-menu'>
            <div onClick={() =>{setIsOpen(false)}}>
                <p onClick={() => {setNavType("about-us")}}>
                    <NavLink to='/'>About Us</NavLink>
                </p>
            </div>
            <div onClick={() =>{setIsOpen(false)}}>
                <p onClick={() => {setNavType("premium")}}>
                    <NavLink to='/premium'>Premium</NavLink>
                </p>
            </div>
            <div onClick={() =>{setIsOpen(false)}}>
                <p onClick={() => {setNavType("contact-us")}}>
                    <NavLink to='/contact-us'>Contact Us</NavLink>
                </p>
            </div>
        </div>
        <div className='nav-socials'>
            <CgFacebook style={{color: "#1B75BC"}}/>
            <FaTwitter style={{color: "#1B75BC"}}/>
            <FaLinkedinIn style={{color: "#1B75BC"}}/>
            <FaInstagram style={{color: "#1B75BC"}}/>
        </div>
    </div>
  )
}

export default NavModal