import React, { useRef, useEffect, useState } from 'react';
import './VideoPlayer.css';

const VideoPlayer = () => {

  const videoRef = useRef(null);
  const [startVideo, setStartVideo] = useState(true);

  // useEffect(() => {
  //   const video = videoRef.current;

  //   const options = {
  //     root: null,
  //     rootMargin: '0px',
  //     threshold: 0.5, // Adjust this threshold as needed
  //   };

  //   const handleIntersection = (entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         video.play();
  //       } else {
  //         video.pause();
  //       }
  //     });
  //   };

  //   const observer = new IntersectionObserver(handleIntersection, options);
  //   observer.observe(video);

  //   return () => {
  //     observer.unobserve(video);
  //   };
  // }, []);


  return (
    <div className='video-section-container'>
      {startVideo && <div className="anc" onClick={() => {
        videoRef.current.play()
        setStartVideo(false)
      }}></div>}
      <video 
      ref={videoRef}
      width="100%" 
      height="auto" 
      className='video-section' 
      style={{borderRadius:'8px'}} 
      playsInline
      preload="metadata"
      poster={require('../../assets/video-capture-5657.png')}
      controls={startVideo ? false : true}>
        <source src={require('../../assets/joompa-video.mp4')} type="video/mp4" />
        {/* If you want to provide fallback content for browsers that don't support video */}
        Your browser does not support the video tag.
      </video>
      
    </div>


  );
};

export default VideoPlayer;