import React from "react";
import { useLocation } from "react-router-dom";
import Success from "../../assets/Success-3 1.png";
import Error from "../../assets/Error 1.png";

function PaymentStatus() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get("status");
  console.log(status)
  return (
    <div>
      {status === "completed" ? (
        <>
          <div style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column",
            height:"100vh",
             width:"70vw",
             margin:"auto"
          }}>
            <div>
                <img src={Success} alt="Success" />
            </div>
            <div style={{
                marginBottom:"20px",
                fontSize:"20px",
                fontWeight:"600",
            }}>
            Payment Successful!
            </div>
            <div
                style={{
                    color:"#00000080",
                    fontSize:"14px",
                    fontWeight:"400",
                    textAlign:"center"
                }}
            >
            Thank you! Your payment has been successfully processed.
            </div>
          </div>
        </>
      ) : (
        <>
            <div style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection:"column",
                height:"100vh",
                width:"70vw",
                margin:"auto"
            }}>
                <div>
                    <img src={Error} alt="Error" />
                </div>
                <div style={{
                    marginBottom:"20px",
                    fontSize:"20px",
                    fontWeight:"600",
                }}>
                Payment Failed!
                </div>
                <div
                    style={{
                        color:"#00000080",
                        fontSize:"14px",
                        fontWeight:"400",
                        textAlign:"center"
                    }}
                >
                We’re sorry, but your payment could not be processed at this time.
                </div>
            </div>
        </>
      )}
    </div>
  );
}

export default PaymentStatus;
