import React, {useEffect} from "react";
import "./Footer.css";
import logo from "../../assets/logo1.png";
import PlayStoreBtn from "../../assets/playStorebtn.png";
import AppStoreBtn from "../../assets/appStorebtn.png";
// import { CgCPlusPlus } from "react-icons/cg";
import { CgFacebook } from "@react-icons/all-files/cg/CgFacebook";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaPhone } from "@react-icons/all-files/fa/FaPhone";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { ImLocation } from "@react-icons/all-files/im/ImLocation";
import { Link } from "react-router-dom";



export const Footer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="main-box1">
        <div className="box1">
          <img className="logoimg" src={logo} alt="Joompa Logo" />
          <p className="text1">Download the app by clicking the link below :</p>
          <div className="btnSection">
            <a href="https://play.google.com/store/apps/details?id=com.letsjoompa&pcampaignid=web_share">
            <img className="btn1" src={PlayStoreBtn} style={{ cursor: 'pointer' }} alt="" />
            </a>
            {/* <img className="btn2" src={AppStoreBtn} style={{ cursor: 'pointer' }} alt="" /> */}
            <a href="https://apps.apple.com/us/app/lets-joompa/id6457205501"> <img className="btn2" src={AppStoreBtn} style={{ cursor: 'pointer' }} alt="" /></a>
           
          </div>
        </div>
        <div className="box2">
          <p className="b21">Pages</p>
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}><p className="b22">About Us</p></Link>

          <Link to="/premium" style={{ textDecoration: 'none', color: 'white' }}><p className="b23">Premium</p></Link>
          {/* <p className="b23">Premium</p> */}
          <Link to="/contact-us" style={{ textDecoration: 'none', color: 'white' }}><p className="b24">Contact Us</p></Link>
          {/* <p className="b24">Contact</p> */}
        </div>


        <div className="box2-mob">
          <p className="b21">Pages</p>
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}><p className="b22">About Us</p></Link>

          <Link to="/premium" style={{ textDecoration: 'none', color: 'white' }}><p className="b23">Premium</p></Link>
          {/* <p className="b23">Premium</p> */}
          <Link to="/contact-us" style={{ textDecoration: 'none', color: 'white' }}><p className="b24">Contact Us</p></Link>
          {/* <p className="b24">Contact</p> */}
        </div>


        <div className="box3">
          <p className="b31">Contact</p>
          <div className="icons1">

            <div><FaPhone style={{ padding: "0 4rem 0 0", transform: "scaleX(-1)" }} className="fa-phone-icon" /></div>
            <p className="b32"><a style={{color:"#fff"}} href="tel:+65 6333 9049">+65 6333 9049</a></p>
          </div>
          <div className="icons1">
            <div><FaEnvelope style={{ padding: "2rem 0 0 4rem" }} /></div>
            <p className="b33"><a style={{color:"#fff"}} href="mailto:admin@letsjoompa.com">admin@letsjoompa.com</a></p>
          </div>
          <div className="icons1">
          <div><ImLocation style={{ padding: "0 0 0 4rem" }} /></div>
            <p className="b34">60 Paya Lebar Square,<br/> #04-24, Singapore 409051</p>
          </div>
        </div>

        <div className="box3-mob">
          <p className="b31">Contact</p>
          <div className="icons1">
            
            <p className="b32"><FaPhone style={{marginRight:'10px', transform:'scaleX(-1)'}}/><a style={{color:"#fff"}} href="tel:+65 6333 9049">+65 6333 9049</a></p>
          </div>
          <div className="icons1">
            
            <p className="b33"><FaEnvelope style={{marginRight:'10px'}}/><a style={{color:"#fff"}} href="mailto:admin@letsjoompa.com">admin@letsjoompa.com</a></p>
          </div>
          <div className="icons1">

            <ImLocation style={{marginRight:'10px'}}/>
            <p className="b34">60 Paya Lebar Square,<br/> #04-24, Singapore 409051</p>
          </div>
        </div>

        {/* { <div className="footer-mobile">
          <div className="pages-container pr-2 d-flex">

            <div>
              <Link to="/" style={{ textDecoration: 'none', color: 'white' }}><p className="b22"><b>Pages</b></p></Link>

              <Link to="/" style={{ textDecoration: 'none', color: 'white' }}><p className="b22">About us</p></Link>

              <Link to="/premium" style={{ textDecoration: 'none', color: 'white' }}><p className="b23">Premium</p></Link>

              <Link to="/contact-us" style={{ textDecoration: 'none', color: 'white' }}><p className="b24">Contact</p></Link>
            </div>


          </div>

          <div className="pages-container pr-2 d-flex">


            <div>


              <Link to="/" style={{ textDecoration: 'none', color: 'white' }}><p className="b22">About us</p></Link>

              <Link to="/premium" style={{ textDecoration: 'none', color: 'white' }}><p className="b23">Premium</p></Link>

              <Link to="/contact-us" style={{ textDecoration: 'none', color: 'white' }}><p className="b24">Contact</p></Link>
            </div>


          </div>
        </div> } */}

 

        <div className="box4">
          <p className="b41">Social Media</p>
          <div className="social-icons-container">
            <a href="https://www.facebook.com/LetsJoompa" style={{color:"white"}}><CgFacebook style={{ padding: "0 0.8rem 0 4.2rem", cursor: 'pointer' }} /></a>
            {/* <FaTwitter style={{ padding: "0 0.8rem", cursor: 'pointer' }} />
            <FaLinkedinIn style={{ padding: "0 0.8rem", cursor: 'pointer' }} /> */}
             <a href="https://instagram.com/letsjoompa" style={{color:"white"}}><FaInstagram style={{ padding: "0 0.8rem", cursor: 'pointer' }} /></a>
          </div>

          {<div className="social-icons-container-mob">
            <a href="https://www.facebook.com/LetsJoompa" style={{color:"white"}}><CgFacebook style={{ cursor:'pointer' }} /></a>
            {/* <FaTwitter style={{cursor:'pointer' }} />
            <FaLinkedinIn style={{ cursor:'pointer' }} /> */}
            <a href="https://instagram.com/letsjoompa" style={{color:"white"}}><FaInstagram style={{cursor:'pointer' }} /></a>
          </div>}
        </div>
      </div>
      <div className="main-box2">
        <p>© 2023 LetsJoompa. All rights reserved | <Link style={{color:"#fff"}} to="/terms-and-conditions">Terms & Condition</Link> | <Link style={{color:"#fff"}} to="/privacy-policy">Privacy Policy</Link></p>
      </div>
    </div>
  );
};
