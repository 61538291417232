import React, { useEffect, useState } from 'react'
import joompaApi from '../../apis/joompaApi';
import './Privacy.css'
import moment from 'moment/moment';
function Privacy() {
    const [data,setData]=useState();
    const getPrivacyData = async()=>{
        try{
            let res = await joompaApi.get(`/privacyPolicy`);
            let {data} = res?.data;
            setData(data);

        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        console.log("her")
        getPrivacyData();
    },[])
  return (
    <div className='container-main-privacy'>
    <div className='container-privacy'>
        <div className='heading-privacy'>
        Privacy Policy    
        </div>   
        <div className='sub-heading-privacy'>
            Last Updated on : {data && moment(data.updatedAt).format("DD-MM-YYYY")}
        </div>
        </div>   
        {/* <div style={{marginTop:"20px"}}>
            {data && data.content}    
        </div>  */}
        {data && <div style={{marginTop:"20px"}}dangerouslySetInnerHTML={{ __html: data.content }}/>}
    </div>
  )
}

export default Privacy