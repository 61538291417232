import React, {useState, useEffect} from 'react'
import './Navbar.css'
import Logo from "../../assets/logo.png"
import Logo1 from "../../assets/logo1.png"
import CrossBtn from '../../assets/cross-btn.png'
import { Link, NavLink, useLocation } from 'react-router-dom'
import NavModal from '../NavModal/NavModal'

const Navbar = () => {
    const location = useLocation();

    const savedState = localStorage.getItem('navState');
    const initialState = savedState ? JSON.parse(savedState) : "about-us";

    const [isOpen, setIsOpen] = useState(false);
    const [navType, setNavType] = useState(initialState);
    const [navbarCss, setNavbarCss] = useState(false);
    const [activeTab, setActiveTab] = useState('about-us');
    const [logo,setLogo]=useState(Logo);

    localStorage.setItem('navState', JSON.stringify(navType));

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }

    useEffect(()=>{
        console.log(location.pathname)
        const changeNavbarCss = () => {
        if(location.pathname==="/premium" || location.pathname==="/contact-us" || location.pathname==="/terms-and-conditions" || location.pathname==="/privacy-policy"){
            setNavbarCss(true)
            setLogo(Logo)
            console.log(navType, "navtype");
        }
        else{
            setNavbarCss(false)
            setLogo(Logo1)
            console.log(navType, "navtype");
        }
    }
    changeNavbarCss();
    },[location.pathname])
    // useEffect(() =>{
    //     const changeNavbarCss = () => {

    //         localStorage.setItem('navState', JSON.stringify(navType));
    
    //         if(navType==="premium" || navType==="contact-us"){
    //             setNavbarCss(true)
    //             setLogo(Logo)
    //             console.log(navType, "navtype");
    //         }
    //         else{
    //             setNavbarCss(false)
    //             setLogo(Logo1)
    //             console.log(navType, "navtype");
    //         }
    
    //     }
    //     changeNavbarCss();
    // }, [navType])

  return (
    <>
        <div className={`${!navbarCss ? 'main-nav-container-1' : 'main-nav-container-2'}`}>
            <div className='navbar'>
                <div className='logo'>
                    <Link to="/">
                    <img src={logo} style={{width:'175px', height:'auto'}}/>
                    </Link>
                </div>

                <div className='nav-menu'>
                    <ul>
                        <li onClick={() => {setNavType("about-us")}}>
                            <NavLink to='/' className={`tab ${navbarCss ? 'tab-2' : 'tab-1'}`}>About Us</NavLink>
                        </li>
                        <li onClick={() => {setNavType("premium")}} >
                            <NavLink to='/premium' className={`tab ${navbarCss ? 'tab-2' : 'tab-1'}`}>Premium</NavLink>
                        </li>
                        <li onClick={() => {setNavType("contact-us")}} > 
                            <NavLink to='/contact-us' className={`tab ${navbarCss ? 'tab-2' : 'tab-1'}`}>Contact Us</NavLink>
                        </li>
                    </ul>
                </div>

                <div className="hamburger" onClick={toggleNavbar}>
                    <div className={`line ${isOpen ? 'hide-ham' : 'unhide-ham'}`} />
                    <div className={`line ${isOpen ? 'hide-ham' : 'unhide-ham'}`} />
                    <div className={`line ${isOpen ? 'hide-ham' : 'unhide-ham'}`} />
                    <img src={CrossBtn} alt='cross-btn' className={`${isOpen ? 'unhide-ham' : 'hide-ham'}`}/>
                </div>
                
            </div>
            {/* <NavModal isOpen={isOpen}/> */}
        </div>
        <NavModal isOpen={isOpen} setIsOpen={setIsOpen} setNavType={setNavType}/>
    </>
  )
}

export default Navbar